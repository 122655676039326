import React from "react"
import styled from "styled-components"
import media from "../../styles/media"

const Container = styled.div`
  grid-column-end: span 4;
  height: 100%;
  order: 5;

  ${media.tablet`
    grid-column-end: span 6;
    order: 0;
    grid-column-gap: 10px;
    margin-right: 30px;
  `}

  ${media.desktop`
    order: 0;
    column-count: 2;
    grid-column-gap: 50px;
  `}
`

const PerformanceContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  ${media.tablet`
    margin-left: 22px;
  `}
`

const PerformanceWrapper = styled.div`
  div {
    display: inline-block;
  }
  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.red};
    }
  }
`

const PerformanceContent = styled.p`
  margin: 0;
  font-family: StanleyRegular, serif;
  font-size: 16px;
  letter-spacing: -0.27px;
  line-height: 19px;

  ${media.tablet`
    font-size: 20px;
    line-height: 25px;
  `}
`

const DateAndTime = styled.p`
  color: ${props => props.theme.lightGrey};
  margin-bottom: 0;
  letter-spacing: 1.33px;
  font-size: 16px;
  line-height: 20px;
  white-space: pre;

  ${media.tablet`
    margin: 0;
  `}
`

const Title = styled.div`
  line-height: 30px;
  font-weight: bold;

  ${media.tablet`
    margin-left: 22px;
    font-size: 16px;
    margin-bottom: 35px;
  `};
`

export const UpcomingEvents = ({ performances }) => (
  <>
    <Title>Upcoming Performances</Title>
    <Container>
      {performances.map((performance, i) => (
        <PerformanceContainer key={i}>
          <PerformanceWrapper>
            <div>
              <DateAndTime>
                {performance.date} | {performance.time}
              </DateAndTime>
              {performance.linkUrl ? (
                <a
                  href={performance.linkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PerformanceContent>{performance.venue}</PerformanceContent>
                </a>
              ) : (
                <PerformanceContent>{performance.venue}</PerformanceContent>
              )}
              <PerformanceContent>
                {performance.city}, {performance.state}
              </PerformanceContent>
            </div>
          </PerformanceWrapper>
        </PerformanceContainer>
      ))}
    </Container>
  </>
)
