import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import media from "../../styles/media"
import { Carousel } from "./Carousel"

const CopyWrapper = styled.div`
  grid-column-end: span 4;

  ${media.tablet`
    grid-column-end: span 12;

    p:first-child {
      margin-top: 0;
    }
  `}

  ${media.desktop`
    grid-column-end: span 6;
  `}
`

export function CopyAndCarousel({ copy, images }) {
  return (
    <>
      <Carousel images={images} />
      <CopyWrapper dangerouslySetInnerHTML={{ __html: copy }} />
    </>
  )
}

CopyAndCarousel.propTypes = {
  copy: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    })
  ),
}
