import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import media from "../../styles/media"
import { replaceLinksWithNewTabLinks } from "../../utils/common/content"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import truncate from "truncate-html"

const Wrapper = styled.article`
  &.fade-in {
    animation: opacity-change 1.5s;
  }

  @keyframes opacity-change {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  grid-column-end: span 4;
  font-size: 16px;
  line-height: 32px;

  ${media.tablet`
    grid-column-end: span 12;
    font-size: 20px;
  `}

  ${media.desktop`
    grid-column-end: span 6; 
  `}

  p {
    margin: 0 0 32px 0;
    padding: 0;
  }
`

const ShowMoreButton = styled.button`
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  font: inherit;
  &:hover {
    color: ${props => props.theme.red};
  }
`

export function BodyCopy({ body, wrapperRef }) {
  const [showBody, setShowBody] = useState(false)
  const startingBody = truncate(body, 100, {
    byWords: true,
    keepWhitespaces: true,
  })

  return (
    <Wrapper ref={wrapperRef}>
      <div
        dangerouslySetInnerHTML={{
          __html: replaceLinksWithNewTabLinks(showBody ? body : startingBody),
        }}
      ></div>
      {startingBody !== body && (
        <ShowMoreButton onClick={() => setShowBody(!showBody)}>
          {!showBody ? (
            <>
              Read More <ArrowDropDownIcon />
            </>
          ) : (
            <>
              Collapse <ArrowDropUpIcon />
            </>
          )}
        </ShowMoreButton>
      )}
    </Wrapper>
  )
}

BodyCopy.propTypes = {
  body: PropTypes.string,
  wrapperRef: PropTypes.any,
}
