import React, { useRef } from "react"
import PropTypes from "prop-types"

import { BodyCopy } from "./BodyCopy"
import { Carousel } from "./Carousel"
import { ArtistQuote } from "./ArtistQuote"
import { AudioVideoGallery } from "./AudioVideoGallery"
import { RuleLine } from "../common"

const contentComponentTypes = {
  carousel: "ContentfulComponentImageCarousel",
  artistQuote: "ContentfulComponentQuote",
  bodyCopy: "ContentfulComponentBodyCopy",
  audioVideoGallery: "ContentfulComponentAudioVideoGallery",
}

export function ContentComponents({ components }) {
  const bodyCopyRef = useRef(null)
  try {
    const mappedComponents = components.map((component, i) => {
      const key = `${component.__typename}_${i}`

      switch (component.__typename) {
        case contentComponentTypes.bodyCopy:
          const body = component.body.childMarkdownRemark.html

          return <BodyCopy body={body} key={key} wrapperRef={bodyCopyRef} />

        case contentComponentTypes.carousel:
          const images = (component.images || []).map(image => ({
            caption: image.description,
            url: image.file.url,
            fluid: image.fluid,
          }))

          return (
            <Carousel images={images} key={key} bodyCopyRef={bodyCopyRef} />
          )

        case contentComponentTypes.artistQuote:
          const quote = component.captionCopy.captionCopy
          const author = component.author
          const linkUrl = component.linkUrl

          const isFirst = i === 0

          return (
            <>
              {!isFirst && <RuleLine />}
              <ArtistQuote
                quote={quote}
                author={author}
                linkUrl={linkUrl}
                key={key}
              />
              {/* This assumes the page will never start with two quotes in a row */}
              {isFirst && <RuleLine />}
            </>
          )

        case contentComponentTypes.audioVideoGallery:
          const { entries } = component

          return (
            <AudioVideoGallery
              entries={entries.map(entry => ({
                title: entry.title,
                subtitle: entry.subtitle,
                url: entry.videoUrl || entry.audioFile.file.url,
                imageUrl: entry.image && entry.image.file.url,
                type: entry.__typename,
              }))}
              key={key}
            />
          )

        default:
          console.error(
            `Unrecognized content component: ${component.__typename}`
          )

          return null
      }
    })

    return [<RuleLine />, ...mappedComponents, <RuleLine />]
  } catch (error) {
    console.error(error)
    return null
  }
}

ContentComponents.propTypes = {
  components: PropTypes.arrayOf(
    PropTypes.shape({ __typename: PropTypes.string.isRequired })
  ),
}
