import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import media from "../../styles/media"
import { GridContainer, RuleLine } from "../common"
import { PlayButton, PauseButton } from "../icons"

import { VideoEmbed } from "./VideoEmbed"

const Wrapper = styled(GridContainer)`
  padding: 0;
  grid-column: 1 / -1;

  ${media.tablet`
    padding: 0;
  `}
`

const LeftWrapper = styled.div`
  grid-column: 1 / -1;
  height: 50vh;

  ${media.tablet`
    grid-column: 1 / span 6;
  `}

  .player {
    height: 100%;
    width: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center center;
    font-family: "object-fit: cover; object-position: center center;"; //ie polyfill
  }
`

const EntryList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  grid-column: 1 / -1;

  li {
    display: flex;
    align-items: center;
    padding: 30px 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${props => props.theme.black};
    }
  }

  ${media.tablet`
    grid-column: auto / span 6;
  `}
`

const Button = styled.button`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  flex: 1;
`

const PlayPauseButton = styled(Button)`
  text-align: right;
  margin-right: 20px;

  &:hover {
    #Stroke-22,
    #Stroke-1 {
      stroke: ${props => props.theme.red};
    }
  }
`

const EntryButton = styled(Button)`
  color: ${props =>
    props.active ? props.theme.black : props.theme.lightGrey3};
  font-size: 16px;
  letter-spacing: 0.07px;
  line-height: 20px;
  text-align: left;

  &:hover {
    color: ${props => props.theme.red};
  }

  .subtitle {
    color: ${props => props.theme.lightGrey3};
  }
`

const AUDIO_TYPE = "ContentfulArtistAudio"
const VIDEO_TYPE = "ContentfulArtistVideo"

export function AudioVideoGallery({ entries }) {
  const [currentEntryIndex, setCurrentEntryIndex] = useState(0)
  const [playingIndex, setPlayingIndex] = useState(null)
  const [player, setPlayer] = useState(null)
  const audioRef = useRef(null)

  const activeEntry = entries[currentEntryIndex]

  // Every time the player state changes (e.g. video is selected, play/pause button pressed),
  // we need to call out to the native methods on our Vimeo/YouTube player to reflect that state
  // change
  useEffect(() => {
    if (activeEntry.type === AUDIO_TYPE) {
      if (audioRef.current) {
        try {
          playingIndex === currentEntryIndex
            ? audioRef.current.play()
            : audioRef.current.pause()
        } catch (error) {
          // These audio/video controls are a little finicky when mounting/unmounting, so we're squashing
          // errors that bubble up from the YouTube embedded player here
        }
      }
    } else if (activeEntry.type === VIDEO_TYPE) {
      if (player) {
        try {
          playingIndex === currentEntryIndex ? player.play() : player.pause()
        } catch (error) {
          // These audio/video controls are a little finicky when mounting/unmounting, so we're squashing
          // errors that bubble up from the YouTube embedded player here
        }
      }
    }
  }, [activeEntry.type, currentEntryIndex, player, playingIndex])

  function onPlay() {
    setPlayingIndex(currentEntryIndex)
  }

  function onPause() {
    setPlayingIndex(null)
  }

  return (
    <>
      <RuleLine />
      <Wrapper>
        <LeftWrapper>
          {activeEntry.type === AUDIO_TYPE ? (
            <>
              <audio src={activeEntry.url} ref={audioRef} />
              <img src={activeEntry.imageUrl} alt={activeEntry.title} />
            </>
          ) : (
            <VideoEmbed
              url={activeEntry.url}
              onReady={player => setPlayer(player)}
              onPlay={onPlay}
              onPause={onPause}
            />
          )}
        </LeftWrapper>

        <EntryList>
          {entries.map((entry, index) => (
            <li key={index}>
              <EntryButton
                active={index === currentEntryIndex}
                onClick={() => {
                  setCurrentEntryIndex(index)
                  setPlayingIndex(null)
                }}
              >
                <span className="title">{entry.title}</span>{" "}
                <span className="subtitle">{entry.subtitle}</span>
              </EntryButton>

              {activeEntry.type === AUDIO_TYPE ||
              (activeEntry.type === VIDEO_TYPE && player) ? (
                <PlayPauseButton
                  onClick={() => {
                    if (index === currentEntryIndex) {
                      index === playingIndex
                        ? setPlayingIndex(null)
                        : setPlayingIndex(index)

                      return
                    }

                    setPlayingIndex(index)
                    setCurrentEntryIndex(index)
                  }}
                >
                  {playingIndex === index ? <PauseButton /> : <PlayButton />}
                </PlayPauseButton>
              ) : null}
            </li>
          ))}
        </EntryList>
      </Wrapper>
    </>
  )
}

AudioVideoGallery.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      url: PropTypes.string,
    })
  ),
}
