import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import media from "../../styles/media"

const Wrapper = styled.div`
  grid-column-end: span 4;

  ${media.tablet`
    grid-column-end: span 6;
  `}

  iframe {
    min-height: 179px;
    width: 100%;

    ${media.tablet`
      height: 50vh;
    `};
  }
`

function formatLink(link) {
  return link.replace("open.spotify.com/", "open.spotify.com/embed/")
}

export function SpotifyPlayer({ link }) {
  return (
    <Wrapper>
      <iframe
        title="Spotify Player"
        src={formatLink(link)}
        width="300"
        height="380"
        frameBorder="0"
        allowtransparency="true"
        allow="encrypted-media"
      />
    </Wrapper>
  )
}

SpotifyPlayer.propTypes = {
  link: PropTypes.string.isRequired,
}
