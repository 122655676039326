import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"

import media from "../../styles/media"
import { GridContainer } from "../common"
import { replaceLinksWithNewTabLinks } from "../../utils/common/content"

const ManagerButton = styled.div`
  display: block;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.red};
  }

  li {
    list-style: none;
  }
`

const Wrapper = styled(GridContainer)`
  margin-bottom: 45px;

  ${media.tablet`
    margin-bottom: 58px;
  `};
`

const SectionWrapper = styled.div`
  grid-column-end: span ${props => (props.last ? 4 : 2)};
  margin-top: 29px;
  font-size: 16px;
  white-space: pre-line;
  line-height: 30px;
  letter-spacing: 0;
  font-family: StanleyRegular, serif;

  ${media.tablet`
    margin-top: 31px;
  `}

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.red};
    }
  }
`

const SectionTitle = styled.strong`
  font-family: "neue-haas-grotesk-text", sans-serif;
  display: block;
`

const Content = styled.div`
  p {
    margin: 0;
    ${props => {
      if (props.last) {
        return css`
          columns: 2 auto;
        `
      }
    }}
  }
`

export function ArtistMeta({
  managers,
  territory,
  pressResources,
  connect,
  name: artistName,
  onManagerClick,
}) {
  const contentMap = {
    Territory: territory,
  }

  if (pressResources) {
    contentMap["Press Resources"] = pressResources
  }

  if (connect) {
    contentMap.Connect = connect
  }

  return (
    <Wrapper>
      {managers && (
        <SectionWrapper>
          <SectionTitle>Managers</SectionTitle>
          <Content>
            <ManagerButton onClick={onManagerClick}>
              {managers.map(manager => (
                <li>{manager}</li>
              ))}
            </ManagerButton>
          </Content>
        </SectionWrapper>
      )}

      {Object.entries(contentMap).map(([name, content], index) => {
        const last = Object.keys(contentMap).length - 1 === index

        return (
          <SectionWrapper key={name} last={last}>
            <SectionTitle>{name}</SectionTitle>
            <Content
              dangerouslySetInnerHTML={{
                __html: replaceLinksWithNewTabLinks(content),
              }}
              last={last}
            />
            {name === "Managers" && (
              <a
                href={`mailto:webinfo@columbia-artists.com?subject=${encodeURIComponent(
                  `Columbia Artists Contact Request - ${artistName}`
                )}`}
              >
                Contact a Manager
              </a>
            )}
          </SectionWrapper>
        )
      })}
    </Wrapper>
  )
}

ArtistMeta.propTypes = {
  managers: PropTypes.arrayOf(PropTypes.string),
  territory: PropTypes.string,
  pressResources: PropTypes.string,
  connect: PropTypes.string,
  name: PropTypes.string,
  onManagerClick: PropTypes.func,
}
