import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { isBefore } from "date-fns"

import media, { sizes } from "../styles/media"
import {
  ArtistMeta,
  ContentComponents,
  SpotifyPlayer,
  NewsItem,
  UpcomingEvents,
} from "../components/artist"
import { GridContainer, Hero, Layout } from "../components/common"
import SEO from "../components/common/SEO"
import { ContactForm } from "../components/contact/ContactForm"

const PerformancesWrapper = styled.div`
  grid-column-end: span 4;
  order: 6;

  ${media.tablet`
    grid-column-end: span 12;
  `}

  @media (min-width:1199px) {
    grid-column-end: span 6;
    order: 6;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 14px;

    ${media.desktop`
      margin-bottom: 55px;
    `}
  }
`

const NewsItemWrapper = styled.div`
  grid-column: 1;
  grid-column-end: 5;

  ${media.tablet`
    grid-column-end: span 12;
  `}

  @media (min-width:1199px) {
    grid-column: 1;
    grid-column-end: span 6;
    margin-left: 22px;
  }
`

const ContactFormWrapper = styled.div`
  z-index: 3;
  overflow: visible;
  position: fixed;
`

const Title = styled.div`
  line-height: 30px;
  font-weight: bold;

  ${media.desktop`
    font-size: 16px;
  `};
`

function Artist({ data, location }) {
  const hasHash = typeof location !== "undefined" && location.hash.length > 0

  const [showContactForm, setShowContactForm] = useState(false)

  const {
    contentfulArtist: {
      name,
      categories,
      heroImage,
      mobileHeroImage,
      croppedMobileHeroImage,
      useDarkLayout,
      territory,
      components,
      spotifyPlayerLink,
      slug,
      managerContactEmail,
    },
  } = data

  const loadedAt = new Date()

  const managers =
    data.contentfulArtist.managers &&
    data.contentfulArtist.managers.map(entry => entry.name)

  const pressResources =
    data.contentfulArtist.pressResources &&
    data.contentfulArtist.pressResources.childMarkdownRemark.html

  const connect =
    data.contentfulArtist.connect &&
    data.contentfulArtist.connect.childMarkdownRemark.html

  const performanceContent =
    data.contentfulArtist.performances &&
    data.contentfulArtist.performances.filter(performance =>
      isBefore(loadedAt, new Date(performance.expirationDate))
    )
  const relatedArticlesContent = data.contentfulArtist.relatedArticles

  let imageUrl =
    data.artistPageMetaContent && data.artistPageMetaContent.metaImage
      ? "https:" + data.artistPageMetaContent.metaImage.file.url
      : undefined

  // If no meta image was provided, use the Artist's hero image
  if (!imageUrl) {
    imageUrl = heroImage ? "https:" + heroImage.file.url : undefined
  }

  const twitterImageUrl =
    data.artistPageMetaContent && data.artistPageMetaContent.metaImageTwitter
      ? "https" + data.artistPageMetaContent.metaImageTwitter.file.url
      : imageUrl

  let heroSources = {}

  if (heroImage) {
    // If an explcit mobile image is provided, use that instead of the auto-cropped asset
    if (mobileHeroImage) {
      heroSources = [
        mobileHeroImage.fluid,
        {
          ...heroImage.fluid,
          media: `(min-width: ${sizes.tablet}px)`,
        },
      ]
    } else {
      // Otherwise, fall back to the desktop asset + the auto-cropped one
      heroSources = [
        croppedMobileHeroImage.fluid,
        {
          ...heroImage.fluid,
          media: `(min-width: ${sizes.tablet}px)`,
        },
      ]
    }
  }

  return (
    <Layout
      hasHero
      hasHash={hasHash}
      headerTheme={useDarkLayout ? "dark" : "light"}
    >
      <SEO
        title={`Columbia Artists - ${name}`}
        path={`/artists/${slug}`}
        description={
          data.artistPageMetaContent
            ? data.artistPageMetaContent.metaDescription.metaDescription
            : undefined
        }
        imageUrl={imageUrl}
        twitterImageUrl={twitterImageUrl}
      />

      <Hero
        title={name}
        categories={categories}
        fluid={heroSources}
        dark={useDarkLayout}
      />

      <ArtistMeta
        {...{ managers, territory, pressResources, connect, name }}
        onManagerClick={() => setShowContactForm(true)}
      />
      {showContactForm && (
        <ContactFormWrapper>
          <ContactForm
            hide={() => setShowContactForm(false)}
            recipientEmail={managerContactEmail}
          />
        </ContactFormWrapper>
      )}
      <GridContainer
        css={`
          padding-bottom: 30px;

          ${media.tablet`
            padding-bottom: 30px;
          `}
        `}
      >
        {components && <ContentComponents components={components} />}
        {spotifyPlayerLink && <SpotifyPlayer link={spotifyPlayerLink} />}
        {performanceContent && performanceContent.length ? (
          <PerformancesWrapper>
            <UpcomingEvents performances={performanceContent} />
          </PerformancesWrapper>
        ) : null}{" "}
        {relatedArticlesContent && (
          <NewsItemWrapper>
            <Title>Related Articles</Title>
            <NewsItem relatedArticles={relatedArticlesContent} />
          </NewsItemWrapper>
        )}
      </GridContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query GetArtist($slug: String!) {
    contentfulArtist(slug: { eq: $slug }) {
      artistPageMetaContent {
        metaDescription {
          metaDescription
        }
        metaImageTwitter {
          file {
            url
          }
        }
        metaImage {
          file {
            url
          }
        }
      }
      name
      slug
      heroImage {
        file {
          url
        }
        fluid(maxWidth: 1600, quality: 70) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      croppedMobileHeroImage: heroImage {
        fluid(
          maxWidth: 750
          maxHeight: 810
          quality: 70
          resizingBehavior: FILL
          cropFocus: FACES
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      mobileHeroImage {
        fluid(
          maxWidth: 750
          maxHeight: 810
          quality: 70
          resizingBehavior: FILL
          cropFocus: CENTER
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      useDarkLayout
      managerContactEmail
      managers {
        ...ContentfulContactEntry
      }
      territory
      pressResources {
        childMarkdownRemark {
          html
        }
      }
      connect {
        childMarkdownRemark {
          html
        }
      }
      components {
        ...ContentfulComponentImageCarouselFields
        ...ContentfulComponentQuoteFields
        ...ContentfulComponentBodyCopyFields
        ...ContentfulComponentAudioVideoGalleryFields
      }
      spotifyPlayerLink
      performances {
        ...ContentfulPerformance
      }
      relatedArticles {
        title
        slug
        date
        thumbnail {
          fluid(maxWidth: 800, maxHeight: 450) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          file {
            url
          }
        }
      }
      categories {
        name
        parentCategory: category {
          name
        }
        keepPlural
      }
    }
  }
`

export default Artist
