import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import media from "../../styles/media"
import { replaceQuotes } from "../../utils/common/transforms"

const Wrapper = styled.div`
  color: ${props => props.theme.black};
  grid-column: span 4;

  padding: 13px 0;
  min-width: 0;

  ${media.tablet`
    grid-column: span 12;
  `}
`

const Quote = styled.div`
  text-align: left;
  letter-spacing: 0;
  font-size: 24px;
  font-family: StanleyRegular, serif;

  ${media.desktop`
    font-size:45px;
    margin-bottom: 32px;
    line-height: 52px;
  `}
`

const Author = styled.div`
  margin-top: 15px;
  color: ${props => props.theme.lightGrey3};

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.red};
    }
  }
`

export const ArtistQuote = ({ quote, author, linkUrl }) => {
  return (
    <Wrapper>
      <Quote dangerouslySetInnerHTML={{ __html: replaceQuotes(quote) }} />
      <Author>
        {linkUrl ? (
          <a href={linkUrl} target="_blank" rel="noopener noreferrer">
            {author}
          </a>
        ) : (
          author
        )}
      </Author>
    </Wrapper>
  )
}

ArtistQuote.propTypes = {
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  linkUrl: PropTypes.string,
}
