import React, { Fragment } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"

import media from "../../styles/media"

const Wrapper = styled.div`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  ${media.mobile`
    width: 50%;
`}
`

const NewsContentCard = styled.div`
  min-width: 40%;
  margin-top: 10px;
`
const NewsContentCardText = styled.div`
  min-width: 10%;
  padding-right: 5px;

  ${media.desktop`
    min-width: 20%;
  `}
`

const NewsContent = styled(Link)`
  color: ${props => props.theme.black};
  text-decoration: none;

  p {
    margin-top: 10px;
  }

  &:hover {
    color: ${props => props.theme.red};
  }

  ${media.tablet`
    align-self: center;
    margin-left: 25px;
  `}
`

const Date = styled.p`
  color: ${props => props.theme.lightGrey};
  font-size: 16px;
  letter-spacing: 1.33px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-left: 10px;
`

const NewsText = styled.p`
  margin-left: 10px;
  margin-top: 0;
  font-family: StanleyRegular, serif;
  font-size: 16px;
  letter-spacing: -0.27px;
  line-height: 19px;

  ${media.tablet`
    font-size: 20px;
    line-height: 25px;
  `}
`

const NewsImage = styled.div`
  margin-top: 10px;

  img {
    width: 100%;
    height: 100%;
  }
`

export const NewsItem = ({ relatedArticles }) => (
  <Wrapper>
    {relatedArticles.map((article, i) => (
      <Fragment key={i}>
        <Content>
          <NewsContentCard>
            <NewsImage>
              <Link to={`/news/${article.slug}`}>
                <Img alt={article.title} fluid={article.thumbnail.fluid} />
              </Link>
            </NewsImage>
          </NewsContentCard>
          <NewsContentCardText>
            <NewsContent to={`/news/${article.slug}`}>
              <Date>{article.date}</Date>
              <NewsText>{article.title}</NewsText>
            </NewsContent>
          </NewsContentCardText>
        </Content>
      </Fragment>
    ))}
  </Wrapper>
)
