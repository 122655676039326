import React from "react"
import PropTypes from "prop-types"
import Youtube from "react-youtube"
import VimeoPlayer from "@vimeo/player"

// YouTube regex via https://stackoverflow.com/a/30795206
const youTubeRegex = /(?:https?:\/\/)?(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:watch|v|embed)(?:\.php)?(?:\?.*v=|\/))([a-zA-Z0-9\-_]+)/i
const vimeoRegex = /https:\/\/vimeo\.com\/([a-zA-Z0-9-_]+)/i

export function VideoEmbed({ url, onReady, onPlay, onPause }) {
  function onYouTubeReady(e) {
    const player = {
      play: () => e.target.playVideo(),
      pause: () => e.target.pauseVideo(),
    }
    if (onReady) {
      onReady(player)
    }
  }

  function vimeoRef(element) {
    if (!element) {
      return
    }

    const player = new VimeoPlayer(element)
    if (onPlay) {
      player.on("play", onPlay)
    }
    if (onPause) {
      player.on("pause", onPause)
    }
    if (onReady) {
      onReady(player)
    }
  }

  const youTubeVideoId = getYouTubeVideoId(url)
  const vimeoUrl = getVimeoEmbedUrl(url)

  if (youTubeVideoId) {
    return (
      <Youtube
        videoId={youTubeVideoId}
        opts={{ height: "100%", width: "100%" }}
        onReady={onYouTubeReady}
        containerClassName="player"
        onPlay={onPlay}
        onPause={onPause}
      />
    )
  }

  if (vimeoUrl) {
    return (
      <iframe
        style={{
          height: "100%",
          width: "100%",
          border: "none",
        }}
        title={vimeoUrl}
        src={vimeoUrl}
        ref={vimeoRef}
        allowFullScreen
      />
    )
  }
}

function getYouTubeVideoId(url) {
  const match = url.match(youTubeRegex)

  if (!match) {
    return null
  }

  const videoId = match[1]

  return videoId
}

function getVimeoEmbedUrl(url) {
  const match = url.match(vimeoRegex)

  if (!match) {
    return null
  }

  const videoId = match[1]

  return `https://player.vimeo.com/video/${videoId}`
}

VideoEmbed.propTypes = {
  url: PropTypes.string.isRequired,
  onReady: PropTypes.func,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
}
