import React, { useState } from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"
import styled from "styled-components"
import Img from "gatsby-image"

import media from "../../styles/media"
import { Button } from "../common"
import CarouselArrow from "./CarouselArrow"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const ArrowWrapper = styled.div`
  display: none;

  ${media.tablet`
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 0;
  `}
`

const SliderWrapper = styled.div`
  grid-column-end: span 4;
  min-width: 0;
  position: relative;

  ${media.tablet`
    grid-column-end: span 12;
    padding-right: 60px;
  `}

  ${media.desktop`
    grid-column-end: span 6;  
  `}
`

const Caption = styled.p`
  color: ${props => props.theme.lightGrey3};
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
  text-align: left;
`

export function Carousel({ images, bodyCopyRef }) {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [slider, setSlider] = useState(null)

  const sliderRef = element => {
    setSlider(element)
  }

  return (
    <SliderWrapper>
      <Slider
        adaptiveHeight={true}
        afterChange={i => setCurrentSlideIndex(i)}
        dots={false}
        draggable={false}
        infinite={false}
        ref={sliderRef}
        slidesToShow={1}
        speed={350}
      >
        {images.map(({ fluid, url, caption }) => (
          <Button onClick={slider && slider.slickNext} key={url}>
            <Img
              fluid={fluid}
              loading="eager"
              src={url}
              alt={caption}
              style={{
                height: "100%",
                width: "100%",
                cursor: images.length > 1 ? "pointer" : "default",
              }}
              imgStyle={{
                objectPosition: "top center",
                objectFit: "contain",
              }}
            />
            <Caption>{images[currentSlideIndex].caption}</Caption>
          </Button>
        ))}
      </Slider>

      {slider && (
        <ArrowWrapper>
          <CarouselArrow
            disabled={currentSlideIndex === images.length - 1}
            direction="right"
            onClick={slider.slickNext}
          />
          <CarouselArrow
            disabled={currentSlideIndex === 0}
            direction="left"
            onClick={slider.slickPrev}
          />
        </ArrowWrapper>
      )}
    </SliderWrapper>
  )
}

Carousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      caption: PropTypes.string,
    })
  ),
}
